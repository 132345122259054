import React, { useEffect, useState } from 'react';
import transcriptsData from '../assets/transcripts.json'; // Adjust path if necessary

export function VideoTranscript({ videoId }) {
    const [transcriptData, setTranscriptData] = useState(null);

    useEffect(() => {
        const loadTranscript = () => {
            const videoTranscript = transcriptsData.find(item => item.youtubeId === videoId);
            setTranscriptData(videoTranscript || { title: 'Transcript not found', transcript: '' });
        };
        loadTranscript();
    }, [videoId]);

    return (
        <div id="video-transcript" className="video-description visually-hidden" tabIndex={0}>
            {transcriptData && (
                <>
                    {transcriptData.title && <h3>{`Video Transcript en Beschrijving voor "${transcriptData.title}"`}</h3>}
                    <p>{transcriptData.transcript}</p>
                </>
            )}
        </div>
    );
}

export default VideoTranscript;
