const DevFilterItem = ({ label, active, value, callback }) => {
  return (
    <div className="theme-filter__cluster-list-item">
      <input
        type="checkbox"
        id={`cluster-filter-${value}`}
        checked={active}
        onChange={(e) => {
          callback(value, e.target.checked);
        }}
      />
      <label htmlFor={`cluster-filter-${value}`}>{label.replace(/CO2/g, 'CO₂')}</label>
    </div>
  );
};

export default DevFilterItem;
