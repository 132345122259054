import {Helmet} from "react-helmet";

export const HeadMeta =  ({pageTitle}) => {
    return (
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
    )
}

export default HeadMeta;