import { useState, useEffect } from "react";
import wordListParser from "../helpers/wordList";
import { getThesaurus } from "../api";

const useParsedDispositionContent = (toelichting) => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        const fetchContent = async () => {
            if (!toelichting) return;

            try {
                const thesaurusData = await getThesaurus();
                const parsedContent = wordListParser(toelichting, thesaurusData);
                setContent(parsedContent);
            } catch (error) {
                console.error("Error fetching thesaurus or parsing content:", error);
            }
        };

        fetchContent();
    }, [toelichting]);

    return content;
};

export default useParsedDispositionContent;
