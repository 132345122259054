import { useEffect } from "react";
import {useState, useDispatch, useRefProvider} from "../hooks/useReducer";
import FocusLock from "react-focus-lock";
import {useSearchParams} from "react-router-dom";
import {ThemeFilterMobileIcon} from "./Icons";

const FilterItem = ({ handleClick, label, value, active, index }) => (
  <li className="mobile-filter__list-item">
    <input
      type="checkbox"
      id={`${value}-cluster-filter-${index}`}
      checked={active}
      onChange={(e) => {
        handleClick(value, e.target.checked);
      }}
    />
    <label htmlFor={`${value}-cluster-filter-${index}`}>{label}</label>
  </li>
);

const ThemeFilterModal = () => {
  const { clusters, developments } = useState();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const themeFilterOpen = searchParams.get('open-filters') ? true : false;
  const { themesFilterTriggerRef, themesFilterFirstElRef } = useRefProvider();

  useEffect(() => {
    document.body.classList.toggle("modal-open", themeFilterOpen);
    if (!themeFilterOpen) {
      const modalElement = document.getElementById("modal-theme-filter");
      if (modalElement) {
        modalElement.scrollTop = 0;
      }
    }
  }, [themeFilterOpen]);

  const handleClosePanel = () => {
    searchParams.delete('open-filters');
    setSearchParams(searchParams);
  };
  const handleOnKeyDown = (event) => {
    const { key, shiftKey } = event;
    if (key === "Escape") {
      handleClosePanel();
    }
    if (shiftKey) {
      if (document.activeElement === themesFilterFirstElRef.current) {
        event.preventDefault();
      }
    }
  }
  const handleClusterClick = (id, state) => {
    dispatch({ type: "set_cluster", payload: { id, state } });
  };

  const handleDevelopmentClick = (id, state) => {
    dispatch({ type: "set_development", payload: { id, state } });
  };

  const handleClearAll = () => {
    dispatch({ type: "reset_development" });
    dispatch({ type: "reset_all_clusters" });
  };

  return (
      <div
          id={"themes-mobile-filter"}
          className={`mobile-filter ${
              themeFilterOpen ? "mobile-filter--visible" : ""
          }`}
          // onClick={handleClosePanel}
          onKeyDown={handleOnKeyDown}
      >
        <div
            className="mobile-filter__panel"
            onClick={(e) => e.stopPropagation()}
        >
          {themeFilterOpen && (<FocusLock returnFocus={true} onFocusReturn={() => {
            themesFilterTriggerRef.current?.focus()
          }}>
            <button
                className="mobile-filter__close-button"
                onClick={handleClosePanel}
                ref={themesFilterFirstElRef}
                aria-label={"Sluit de themafiltraties"}
            >
              <ThemeFilterMobileIcon />
            </button>
            <div className="mobile-filter__inner-panel" id="modal-theme-filter">
              <div className="container">
                <div className="row">
                  <div className="col-1">
                    <div className="mobile-filter__top-bar">
                      <h2 className="mobile-filter__top-bar-title">Filtering</h2>
                      <button
                          className="mobile-filter__top-bar-button"
                          aria-label="wis alle filters"
                          onClick={handleClearAll}
                      >
                        wis alle filters
                      </button>
                    </div>
                    {clusters.length > 0 && (
                        <div className="mobile-filter__list-panel">
                          <h3 className="mobile-filter__list-panel-title">
                            Clusters
                          </h3>
                          <ul className="mobile-filter__list">
                            {clusters.map((cluster, index) => (
                                <FilterItem
                                    {...cluster}
                                    index={index}
                                    key={index}
                                    handleClick={handleClusterClick}
                                />
                            ))}
                          </ul>
                        </div>
                    )}
                    {developments.length > 0 && (
                        <div className="mobile-filter__list-panel">
                          <h3 className="mobile-filter__list-panel-title">
                            Ontwikkelingen lange termijn
                          </h3>
                          <ul className="mobile-filter__list">
                            {developments.map((development, index) => (
                                <FilterItem
                                    {...development}
                                    index={index}
                                    key={index}
                                    handleClick={handleDevelopmentClick}
                                />
                            ))}
                          </ul>
                        </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </FocusLock>)}

        </div>
      </div>
  );
};

export default ThemeFilterModal;
