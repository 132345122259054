import {useState} from "react";
import { useRefProvider, useState as useReducerState} from "../hooks/useReducer";
import {getImage} from "../helpers/imageParse";
import {AreaIcon, PlusIcon} from "./Icons";
import useIsMobile from "../hooks/useIsMobile";
import {useNavigate, useSearchParams} from "react-router-dom";
import useParsedDispositionContent from "../hooks/useParesedDispositionContent";

const DispositionCard = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {
        ancestors,
        hideTheme = false,
        id,
        inhoud = {},
        themeTitle,
        title
    } = props;

    const label = ancestors?.[0]?.label;
    const {
        toelichting,
        gebied = [],
        hoofdafbeelding,
        periodeStart = "",
        periodeEind = "",
    } = inhoud;
    const year = periodeStart.slice(0, 4) + " - " + periodeEind.slice(0, 4);
    const image = getImage(hoofdafbeelding);
    const [contentOpen, setContentOpen] = useState(false);
    const [areaOpen, setAreaOpen] = useState(false);
    const { setRef, focusRef } = useRefProvider();
    const {areas, dispositionColors} = useReducerState();
    const isDispositionModalOpen = searchParams.get('dispositionId') ? true : false;
    let color = props.color;
    if (id && dispositionColors[id]) {
        color = dispositionColors[id].color;
    }
    const isMobile = useIsMobile();
    const content = useParsedDispositionContent(toelichting);

    const handleContentClick = () => {
        if (!contentOpen) {
            setAreaOpen(false);
        }
        setContentOpen((old) => !old);
    };

    const handleAreaClick = () => {
        if (!areaOpen) {
            setContentOpen(false);
        }
        setAreaOpen((old) => !old);
    };

    const handleMobileClick = () => {
        if (isDispositionModalOpen) {
            return;
        }
        navigate({
            search: `?dispositionId=${id}`,
        });
        focusRef(`popup-modal`);
    };

    return (
        <ul className={`dispositionCard dispositionCard--${color}`}>
                <li className="dispositionCard__theme">
                {!hideTheme && `Thema: ${themeTitle}`}
                { isMobile && <button
                    className="dispositionCard__mobile-button"
                    onClick={handleMobileClick}
                    style={hideTheme ? {top: "-20px"} : {}}
                    aria-expanded={isDispositionModalOpen}
                    aria-label={isDispositionModalOpen ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                    ref={setRef(isDispositionModalOpen ? `popup-modal` : `${id}-no-modal`)}
                >
                    <PlusIcon areaOpen={isDispositionModalOpen} title={title} />
                </button>}
            </li>
            <li className="dispositionCard__cluster">
                <span className="dispositionCard__cluster-inner">{`Cluster: ${label}`}</span>
            </li>

            <li className="dispositionCard__title">
                <span className="dispositionCard__title-inner">{`Maatregel: ${title}`}</span>
            </li>
            <li className="dispositionCard__content">
                <button
                    className="icon-button dispositionCard__button"
                    onClick={handleContentClick}
                    aria-expanded={contentOpen}
                    aria-controls="expandable-text-content"
                    aria-label={contentOpen ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                >
                    <PlusIcon areaOpen={contentOpen} title={title} />
                </button>
                <span
                    id="expandable-text-content"
                    className={`dispositionCard__content-inner ${contentOpen ? "dispositionCard__content-inner--open" : ""}`}
                >
                  {content}
                </span>
            </li>

            <li className="dispositionCard__year">
                <time dateTime={year}>{year}</time>
            </li>

            <li className="dispositionCard__area">
                <button
                    className="icon-button dispositionCard__button"
                    onClick={handleAreaClick}
                    aria-expanded={areaOpen}
                    aria-controls="expandable-content-area"
                    aria-labelledby="area-label"
                    aria-label={areaOpen ? `Verberg gebied van maatregel: ${title}` : `Toon gebied van maatregel: ${title}`}
                >
                    <PlusIcon areaOpen={areaOpen} title={title} />
                </button>
                <span id="area-label" className="dispositionCard__area-inner">Gebied: </span>
                <div
                    id="expandable-content-area"
                    className={`dispositionCard__area-expand ${areaOpen ? "dispositionCard__area-expand--open" : ""}`}
                    role="region"
                    aria-labelledby="area-label"
                >
                    <span id="area-label" className="visually-hidden">Gebied details</span>
                    <ul className="dispositionCard__area-list">
                        {areas.map((a, i) => (
                            <li
                                className={`dispositionCard__area-list-item ${gebied.includes(a.value) ? "dispositionCard__area-list-item--active" : ""}`}
                                key={i}
                            >
                                <AreaIcon id={a.value} aria-hidden="true" />
                                {a.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </li>

            <li
                className={`dispositionCard__image ${contentOpen ? "dispositionCard__image--open-content" : ""} ${areaOpen ? "dispositionCard__image--open-area" : ""}`}
                style={{
                    backgroundImage: image === "" ? "" : `url(${process.env.REACT_APP_HOST}${image})`
                }}
                aria-hidden="true"
            ></li>
        </ul>
    );
};

export default DispositionCard;
