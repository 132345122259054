import { useState, useEffect } from 'react';
import { breakpoint } from "./useViewport";

// Custom hook to check if the device is mobile
const useIsMobile = (mobileBreakpoint = breakpoint) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileBreakpoint);

    useEffect(() => {
        // Update isMobile state on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth < mobileBreakpoint);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [mobileBreakpoint]);

    return isMobile;
};

export default useIsMobile;
