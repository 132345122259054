import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useFocusOnFirstElement = (trigger) => {
    const location = useLocation();
    useEffect(() => {
        if (!trigger) return;
        const firstFocusableElement = document.querySelector(
            'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }, [location]);
};

export default useFocusOnFirstElement;
