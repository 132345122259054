import { useState, useRef, useEffect } from "react";
import { usePopper } from "react-popper";
import {getThesaurus} from "../api";

const ContextPopper = ({ title, matchedTextWord }) => {
  const [visible, setVisibility] = useState(false);
  const [content, setContent] = useState("");

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useEffect(() => {

    getThesaurus()
        .then((thesaurusData) => {
          for(const item of thesaurusData) {
            if (title === item?.name) {
              setContent(item?.explanation);
              break;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });

  }, [title]);

  function handleDocumentClick(event) {
    if (
        referenceRef.current &&
        !referenceRef.current.contains(event.target) &&
        popperRef.current &&
        !popperRef.current.contains(event.target)
    ) {
      setVisibility(false);
    }
  }
  function handleDropdownOpenClick(event) {
    if (!visible) {
      setVisibility(true);
      update();
    }
  }

  function handleKeyDown(event) {
    const { key } = event;
    if (key === "Enter" || key === " ") {
      event.preventDefault();
      setVisibility(!visible);
      update();
    }
    if (key === "Escape" || key === "Tab") {
      setVisibility(false);
    }
  }

  return (
    <>
      <button
        ref={referenceRef}
        onClick={handleDropdownOpenClick}
        onKeyDown={handleKeyDown}
        className={"context-popper__button"}
        aria-expanded={visible ? true : false}
      >
        {matchedTextWord}
      </button>
      <span
        ref={popperRef}
        style={{ ...styles.popper, zIndex: 10 }}
        {...attributes.popper}
      >
        <span
          style={styles.offset}
          className={`context-popper__panel ${
            visible ? " context-popper__panel--visible" : ""
          }`}
        >
          <span className="context-popper__title">{title}</span>
          <span>{content}</span>
        </span>
      </span>
    </>
  );
};

export default ContextPopper;