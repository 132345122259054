import {useState, useRef} from "react";
import {useRefProvider, useState as useReducerState} from "../hooks/useReducer";
import {getImage} from "../helpers/imageParse";
import {AreaIcon, PlusIcon} from "./Icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import FocusLock from "react-focus-lock";
import useParsedDispositionContent from "../hooks/useParesedDispositionContent";

const DispositionCardModal = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        ancestors,
        id,
        inhoud = {},
        isModal = false,
        themeTitle,
        title
    } = props;

    const label = ancestors?.[0]?.label;
    const {
        toelichting,
        gebied = [],
        hoofdafbeelding,
        periodeStart = "",
        periodeEind = "",
    } = inhoud;
    const year = periodeStart.slice(0, 4) + " - " + periodeEind.slice(0, 4);
    const image = getImage(hoofdafbeelding);
    const { setRef, focusRef } = useRefProvider();
    const {areas, dispositionColors} = useReducerState();
    const [contentOpen, setContentOpen] = useState(false);
    const [areaOpen, setAreaOpen] = useState(false);
    const content = useParsedDispositionContent(toelichting);
    const isDispositionModalOpen = searchParams.get('dispositionId') ? true : false;
    const popupRef = useRef(null);
    let color = props.color;
    if (id && dispositionColors[id]) {
        color = dispositionColors[id].color;
    }

    const handleContentClick = () => {
        if (!contentOpen) {
            setAreaOpen(false);
        }
        setContentOpen((old) => !old);
    };

    const handleAreaClick = () => {
        if (!areaOpen) {
            setContentOpen(false);
        }
        setAreaOpen((old) => !old);
    };

    const closeModal = () => {
        focusRef(`${id}-no-modal`);

        searchParams.delete('dispositionId');
        searchParams.delete('themeTitle');
        setSearchParams(searchParams);
    }
    const openModal = () => {
        navigate({
            search: `?dispositionId=${id}`,
        });
        focusRef(`popup-modal`);
    }

    const handleMobileClick = () => {
        if (isDispositionModalOpen) {
            closeModal();
        } else {
            openModal();
        }
    };
    const handleOnKeyDown = (event) => {
        const { key } = event;
        if (!isDispositionModalOpen) {
            return;
        }
        if (key === "Escape") {
            closeModal();
        }
    }
    const handleParentModalEl = (event) => {
        if (event.key === "Escape") {
            closeModal();
        }
    };

    if (!id) {
        return null;
    }

    return (
        <FocusLock
            returnFocus={true}
            onFocusReturn={() => {
                focusRef(`${id}-no-modal`);
            }}
        >
            <ul
                className={`dispositionCard dispositionCard--${color} "dispositionCard--modal"`}
                ref={popupRef}
                onKeyDown={handleParentModalEl} >
                <li className="dispositionCard__theme">
                    {`Thema: ${themeTitle}`}
                     <button
                        className="dispositionCard__mobile-button"
                        onClick={handleMobileClick}
                        onKeyDown={handleOnKeyDown}
                        aria-expanded={isModal || isDispositionModalOpen}
                        aria-label={isModal ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                        ref={setRef(isModal ? `popup-modal` : `${id}-no-modal`)}
                    >
                         <PlusIcon areaOpen={true} title={title} />
                    </button>
                </li>
                <li className="dispositionCard__cluster">
                    <span className="dispositionCard__cluster-inner">{`Cluster: ${label}`}</span>
                </li>

                <li className="dispositionCard__title">
                    <span className="dispositionCard__title-inner">{`Maatregel: ${title}`}</span>
                </li>

                <li className="dispositionCard__content">
                    <button
                        className="icon-button dispositionCard__button"
                        onClick={handleContentClick}
                        aria-expanded={contentOpen}
                        aria-controls="expandable-text-content"
                        aria-label={contentOpen ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                    >
                        <PlusIcon areaOpen={contentOpen} title={title} />
                    </button>
                    <span
                        id="expandable-text-content"
                        className={`dispositionCard__content-inner ${contentOpen ? "dispositionCard__content-inner--open" : ""}`}
                    >
                      {content}
                    </span>

                </li>

                <li className="dispositionCard__year">
                    <time dateTime={year}>{year}</time>
                </li>

                <li className="dispositionCard__area">
                    <button
                        className="icon-button dispositionCard__button"
                        onClick={handleAreaClick}
                        aria-expanded={areaOpen}
                        aria-controls="expandable-content-area"
                        aria-labelledby="area-label"
                        aria-label={areaOpen ? `Verberg gebied van maatregel: ${title}` : `Toon gebied van maatregel: ${title}`}
                    >
                        <PlusIcon areaOpen={areaOpen} title={title} />
                    </button>
                    <span id="area-label" className="dispositionCard__area-inner">Gebied: </span>
                    <div
                        id="expandable-content-area"
                        className={`dispositionCard__area-expand ${areaOpen ? "dispositionCard__area-expand--open" : ""}`}
                        role="region"
                        aria-labelledby="area-label"
                    >
                        <span id="area-label" className="visually-hidden">Gebied details</span>
                        <ul className="dispositionCard__area-list">
                            {areas.map((a, i) => (
                                <li
                                    className={`dispositionCard__area-list-item ${gebied.includes(a.value) ? "dispositionCard__area-list-item--active" : ""}`}
                                    key={i}
                                >
                                    <AreaIcon id={a.value} aria-hidden="true" />
                                    {a.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>

                <li
                    className={`dispositionCard__image ${contentOpen ? "dispositionCard__image--open-content" : ""} ${areaOpen ? "dispositionCard__image--open-area" : ""}`}
                    style={{
                        backgroundImage: image === "" ? "" : `url(${process.env.REACT_APP_HOST}${image})`
                    }}
                    aria-hidden="true"
                ></li>
            </ul>
        </FocusLock>
    );
};

export default DispositionCardModal;
