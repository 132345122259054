import React, {forwardRef} from 'react';

const IconButton = forwardRef(({ children, ariaLabel, ariaExpanded = false,  className = "", glow = false, onClick = () => null }, ref) => {

    return (
        <button
            onClick={onClick}
            className={`${className} icon-mobile-button ${
                glow ? "icon-mobile-button--shadow" : ""
            }`}
            aria-label={ariaLabel}
            aria-expanded={ariaExpanded}
            ref={ref}
        >
            {children}
        </button>
    );
});

export default IconButton;
