import { ArrowLeft, ArrowRight } from "./Icons";

const SliderPagination = ({
  currentSlide,
  maxSlides,
  gotoSlide,
  next,
  prev,
  color = "blue",
}) => {
  if (maxSlides === 0) {
    return <></>;
  }
  return (
    <div className={`slider-pagination slider-pagination--${color}`}>
      <button type="button" aria-label="Toon vorige slide met maatregelen" className="slider-pagination__button" onClick={prev}>
        <ArrowLeft title="Toon vorige slide met maatregelen"/>
      </button>
      <ul className="slider-pagination__dots">
        {Array.apply(null, { length: maxSlides + 1 }).map((_, i) => {
          return (
              <li key={i}>
                  <button
                      onClick={() => gotoSlide(i)}
                      aria-label={`Toon slide ${i + 1} met maatregelen`}
                      // id={`knop-${i}`}
                      className={`slider-pagination__dot-button ${
                          i === currentSlide
                              ? "slider-pagination__dot-button--active"
                              : ""
                      }`}
                      // aria-controls={`slide-${i}`}
                      aria-current={i === currentSlide ? true : false}
                  ></button>
              </li>

          );
        })}
      </ul>
      <button className="slider-pagination__button" type="button" aria-label="Toon volgende slide met maatregelen" onClick={next}>
        <ArrowRight title={"Toon volgende slide met maatregelen"} />
      </button>
    </div>
  );
};

export default SliderPagination;
