import { Link, useLocation } from "react-router-dom";
import ClusterResult from "../components/ClusterResult";
import React, { useState, useEffect } from "react";
import ThemeFilter from "../components/ThemeFilter";
import {useDispatch, useState as useGlobalState} from "../hooks/useReducer";
import { getThemes } from "../api";
import { CancelToken } from "axios";
import WaterwerkLogoDark from "../components/WaterwerkLogoDark";
import ThemeFilterModal from "../components/ThemeFilterModal";
import useIsMobile from "../hooks/useIsMobile";
import LogoDelfslandComponent from "../components/LogoDelfsland";
import SkipLink from "../components/SkipLink";
import HeadMeta from "../components/HeadMeta";

function ClusterIndex() {
  const [themes, setThemes] = useState([]);
  const { pathname } = useLocation();
  const { clusters, developments, globalThemes } = useGlobalState();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    setThemes(globalThemes);
  }, [globalThemes]);

  useEffect(() => {
    return () => {
      dispatch({ type: "reset_all_clusters" });
      dispatch({ type: "reset_development" });
    };
  }, [pathname, dispatch]);

  useEffect(() => {
    const source = CancelToken.source();
    const filteredClusters = clusters.filter(({ active }) => active);
    const filteredDevelopments = developments.filter(({ active }) => active);

    if (filteredClusters.length === 0 && filteredDevelopments.length === 0) {
      setThemes(globalThemes);
    } else {
      getThemes(
        clusters.filter(({ active }) => active),
        developments.filter(({ active }) => active),
        source.token
      )
        .then((xs) =>
          setThemes(
            xs.sort((x, y) => {
              const t1 = x.title.toUpperCase();
              const t2 = y.title.toUpperCase();
              return t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
            })
          )
        )
        .catch((e) => console.log(e));
    }

    return () => source.cancel("");
  }, [globalThemes, clusters, developments, themes]);

  return (
    <div className="ClusterIndex">
      <HeadMeta pageTitle={"Thema's | Waterwerk WBP6 Hoogheemraadschap van Delfland"} />
      <SkipLink targetId="main-content"/>
      <header className="ClusterIndex-header">
        <div className="container">
          <div className="row">
            <div className="col-1o2">
              <LogoDelfslandComponent />
            </div>
            <div className="col-1o2 text-align-right">
              <Link to="/" aria-hidden={true} tabIndex="-1">
                <WaterwerkLogoDark/>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <ThemeFilter>
        <h2 role="status">{themes.length} gevonden resultaten</h2>
      </ThemeFilter>
      <div className="ClusterIndex-results" id="main-themes-content">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <h2 role="status">{themes.length} gevonden resultaten</h2>
              <div className="row ClusterIndex-grid">
                {themes.map((result) => (
                  <div className="col-1 col-xl-1o3 col-l-1o2" key={result.id}>
                    <ClusterResult {...result} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && <ThemeFilterModal/>}
    </div>
  );
}

export default ClusterIndex;
