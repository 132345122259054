import {Link} from "react-router-dom";
import LogoDelfsland from "../assets/logo-delfsland.svg";
import {useRefProvider} from "../hooks/useReducer";

export function LogoDelfslandComponent() {
    const {setRef} = useRefProvider();
    return (
        <Link to="/" aria-label={"Logo Hoogheemraadschap van Delfland, ga naar de homepage van Waterwerk WBP6"} ref={setRef("LogoDelfsland")}>
            <img src={LogoDelfsland} alt="Logo Hoogheemraadschap van Delfland, ga naar de homepage van Waterwerk WBP6" />
        </Link>
    )
}
export default LogoDelfslandComponent;
