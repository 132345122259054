import React, {useState, useEffect, useRef} from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {fetchYouTubeData, getTheme, YouTubeGetID} from "../api";
import { Link, useNavigate } from "react-router-dom";
import DispositionSlider from "../components/DispositionSlider";
import { ArrowRight, ArrowLeft } from "../components/Icons";
import { getImage } from "../helpers/imageParse";
import { useState as useGlobalState, useDispatch, useRefProvider } from "../hooks/useReducer";
import { colorByCluster } from "../helpers/colorCluster";
import kader from "../assets/kader.png";
import PlayIcon from "../assets/play-icon.svg";
import ReactHtmlParser from "react-html-parser";
import wordListParser from "../helpers/wordList";
import {getThesaurus} from "../api";
import WaterwerkLogoDark from "../components/WaterwerkLogoDark";
import VideoModal from "../components/VideoModal";
import LogoDelfslandComponent from "../components/LogoDelfsland";
import SkipLink from "../components/SkipLink";
import useIsMobile from "../hooks/useIsMobile";
import HeadMeta from "../components/HeadMeta";
import useFocusOnFirstElement from "../hooks/useFocusOnFirstElement";

const renameList = [
  {
    old: "Richtlijn Overstromingsrisico’s (Ror)",
    new: "Overstromingsrisico’s",
  },
  {
    old: "Richting een circulaire waterketen",
    new: "Transitie waterketen",
  },
  {
    old: "Transporteren, zuiveren en slib verwerken",
    new: "Transporteren en zuiveren van afvalwater",
  },
  {
    old: "Waterkwaliteit",
    new: "Waterkwaliteit, pijler 1: KRW",
  },
  {
    old: "Overig Water",
    new: "Waterkwaliteit, pijler 2: Overig water",
  },
  {
    old: "Overige water",
    new: "Waterkwaliteit, pijler 2: Overig water",
  },
  {
    old: "Zoetwatervoorziening en coördinatie droogte",
    new: "Zoetwatervoorziening en droogte",
  },
  {
    old: "Personeel & organisatie",
    new: "Personeel en organisatie",
  },
  {
    old: "Regulering & toezicht",
    new: "Regulering en toezicht",
  },
];

const optionallyRename = (title) => {
  for (let i = 0; i < renameList.length; i++) {
    if (renameList[i].old === title) {
      return renameList[i].new;
    }
  }
  return title;
};

const ThemaDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [theme, setTheme] = useState(null);
  const [title, setTitle] = useState("");

  const { clusters, globalThemes } = useGlobalState();
  const dispatch = useDispatch();
  const [afb1, setAfb1] = useState(null);
  const [afb2, setAfb2] = useState(null);
  const [afb3, setAfb3] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [youtubeData, setYoutubeData] = useState(null);
  const [docs, setDocumenten] = useState([]);
  const [links, setLinks] = useState([]);
  const [toelichting, setToelichting] = useState(ReactHtmlParser(""));
  const [ambitie, setAmbitie] = useState(ReactHtmlParser(""));
  const [doel, setDoel] = useState(ReactHtmlParser(""));
  const [clustertekst, setClustertekst] = useState(ReactHtmlParser(""));
  const { pathname } = useLocation();
  const { videoTriggerRef } = useRefProvider();
  const isMobile = useIsMobile();
  const [triggerFocus, setTriggerFocus] = useState(false);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const r = await getTheme(id);
        setTheme(r);
        const {
          inhoud = {},
          documenten = [],
          interneLinks = [],
          externeLinks = [],
        } = r;

        const {
          hoofdafbeelding = null,
          afbeelding2 = null,
          afbeelding3 = null,
          videoURL = null,
          toelichting = "",
          ambitie = "",
          doel = "",
          clustertekst = "",
        } = inhoud;

        const retrievedHoofdafbeelding =
            hoofdafbeelding === null ? null : getImage(hoofdafbeelding);
        setAfb1(retrievedHoofdafbeelding);
        setAfb2(
            afbeelding2 === null
                ? retrievedHoofdafbeelding
                : getImage(afbeelding2)
        );
        setAfb3(
            afbeelding3 === null
                ? retrievedHoofdafbeelding
                : getImage(afbeelding3)
        );
        setDocumenten(documenten);
        setLinks(interneLinks.concat(externeLinks));
        setTitle(r.title);

        try {
          const thesaurusData = await getThesaurus();
          setToelichting(wordListParser(toelichting, thesaurusData));
          setAmbitie(wordListParser(ambitie, thesaurusData));
          setDoel(wordListParser(doel, thesaurusData));
          setClustertekst(wordListParser(clustertekst, thesaurusData));
        } catch (error) {
          console.error(error);
        }

        if (videoURL) {
          const videoId = YouTubeGetID(videoURL);
          const videoData = await fetchYouTubeData(videoId);
          setVideoId(videoId)
          setYoutubeData(videoData);
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [
    id,
    theme?.inhoud?.toelichting,
    theme?.inhoud?.ambitie,
    theme?.inhoud?.doel,
    theme?.inhoud?.clustertekst,
  ]);


  const handleClusterClick = (c) => {
    dispatch({ type: "reset_all_clusters" });
    dispatch({ type: "reset_development" });
    dispatch({ type: "set_cluster", payload: { id: c.value, state: true } });
    navigate("/themas");
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const videoIdParam = searchParams.get("videoID");
  const videoIsOpen = videoIdParam ? true : false;

  const handleVideoButton = (id) => {
    searchParams.set("videoID", id);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const getThemeIdByTitle = (t) => {
    let result = "";
    const title = optionallyRename(t);
    globalThemes.forEach(({ label, id }) => {
      if (label === title) {
        result = "/" + id;
      }
    });
    return result;
  };

  useFocusOnFirstElement(triggerFocus);
  const handleLinkClick = () => {
    setTriggerFocus(true);
    setTimeout(() => { // Reset the trigger to false after a short delay
      setTriggerFocus(false);
    }, 100);
  };

  if (theme === null) {
    return <></>;
  }

  return (
    <div
      className={`ClusterDetail cluster--waterkwantiteit ClusterDetail--${colorByCluster(
        theme.inhoud.cluster
      )}`}
    >
      <HeadMeta pageTitle={`${title} | Waterwerk WBP6 Hoogheemraadschap van Delfland`} />
      <SkipLink targetId={"theme-content"} />
      {!isMobile && <Link className="ClusterDetail-back" to="/themas" aria-label={"Ga terug naar themas overzicht"}>
        <ArrowLeft title={"Ga terug naar themas overzicht"} fillColor={"white"}/>
      </Link>}
      <header className="ClusterDetail-header">
        <div className="container">
          <div className="row">
            <div className="col-1o2">
              <LogoDelfslandComponent />
            </div>
            <div className="col-1o2 text-align-right">
              <Link to="/" aria-hidden={true} tabIndex="-1">
                <WaterwerkLogoDark />
              </Link>
            </div>
          </div>
        </div>
      </header>
      {false}
      {(
          <>
            <div className="ClusterDetail-body" id="theme-content">
              <div className="container">
                <div className="row">
                  <div className="col-1">
                    <Link className="ClusterDetail-back-two" to="/themas" aria-label={"Ga terug naar themas overzicht"}>
                      <ArrowLeft title={"Ga terug naar themas overzicht"}/> Naar overzicht
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                      className="col-l-1o3"
                      style={{position: "relative", zIndex: 1}}
                  >
                    <div className="ClusterDetail-title block block--type1">
                      <h1>{title}</h1>
                    </div>
                    <section className="ClusterDetail-intro">
                      {toelichting}
                    </section>
                    <div className="ClusterDetail-legal block">
                      <h2 className="uppercase">Wettelijke Grondslag</h2>
                      <div
                          dangerouslySetInnerHTML={{
                            __html: theme.inhoud.grondslag,
                          }}
                      />
                    </div>
                  </div>
                  <div className="col-l-2o3">
                    <div className="row">
                      <div className="col-l-5o8 offset-l-3o8">
                        <section className="ClusterDetail-development block block--type2">
                          <h2 className="uppercase">
                            Ontwikkelingen lange termijn
                          </h2>
                          <ul>
                            {theme.inhoud.ontwikkelingLangeTermijn.map(
                                (ontwikkeling, index) => (
                                    <li key={index}>{ontwikkeling.replace(/CO2/g, 'CO₂')}</li>
                                )
                            )}
                          </ul>
                        </section>
                      </div>
                      <div className="col-l-5o8 offset-l-1o8">
                        <div className="ClusterDetail__image-one">
                          <div
                              className="ClusterDetail__image-one-inner"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_HOST}${afb1})`,
                              }}
                          ></div>
                          <img
                              src={kader}
                              alt=""
                              className="ClusterDetail__image-one-img"
                          />
                          {videoId && <button
                              className="ClusterDetail__video-button"
                              onClick={() => handleVideoButton(videoId)}
                              aria-label={`Open de video '${youtubeData ? youtubeData.title : ""}'`}
                              aria-haspopup="dialog"
                              aria-expanded={videoIsOpen}
                              aria-controls="video-modal"
                              ref={videoTriggerRef}
                          >
                            <div className="ClusterDetail__video-button-inner">
                              <img src={PlayIcon} alt={`Open de video '${youtubeData ? youtubeData.title : ""}'`}/>
                            </div>
                          </button>}
                        </div>
                        <div className="ClusterDetail-image ClusterDetail-image-1"></div>
                      </div>

                      <div className="col-l-3o8 offset-5o8">
                        <div className="ClusterDetail-image ClusterDetail-image-2">
                          <div
                              className="ClusterDetail-image-2-inner"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_HOST}${afb2})`,
                              }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-l-1o2">
                        <section className="ClusterDetail-objective block block--type1">
                          <h2 className="uppercase">Doel</h2>
                          {doel}
                        </section>
                      </div>

                      <div className="col-l-2o3 offset-l-1o8">
                        <section className="ClusterDetail-ambition block">
                          <h2 className="uppercase">Ambitie</h2>
                          {ambitie}
                        </section>
                      </div>

                      <div className="ClusterDetail-more col-l-5o8 offset-l-3o8">
                        <div className="block ClusterDetail-more-inner">
                          <h2 className="uppercase">Meer weten?</h2>
                          <div>
                            <ul>
                              {docs.map(({document}, i) => (
                                  <li key={`doc-${i}`}>
                                    <a
                                        href={`${process.env.REACT_APP_HOST}${document.link}`}
                                        target="_blank"
                                        className="ClusterDetail-download-link"
                                        rel="noreferrer"
                                    >
                                      {document.label}
                                    </a>
                                  </li>
                              ))}
                              {links.map(({link}, i) => (
                                  <li key={`lnk-${i}`}>
                                    <a
                                        href={link.link}
                                        target="_blank"
                                        className="ClusterDetail-download-link"
                                        rel="noreferrer"
                                    >
                                      {link.label}
                                    </a>
                                  </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DispositionSlider
                color={colorByCluster(theme.inhoud.cluster)}
                dispositionIds={theme.maatregelen.map(
                    ({maatregel}) => maatregel.id
                )}
                themeTitle={theme?.title}
            />
            <div
                className={`container theme-info theme-info--${colorByCluster(
                    theme.inhoud.cluster
                )}`}
            >
              <div className="row">
                <div className="col-1 col-l-1o2">
                  <div className="theme-info__detail theme-info__detail--top-left">
                    <h2 className="theme-info__title">Dwarsverbanden</h2>
                    <ul className="theme-info__dwars-list">
                      {theme.inhoud.dwarsverbanden.map((d, i) => (
                          <li key={i} className="theme-info__dwars-list-item">
                            <Link
                                to={`/themas${getThemeIdByTitle(d)}`}
                                aria-label={`Ga naar thema: ${optionallyRename(d)}`}
                                onClick={handleLinkClick}
                                className="theme-info__dwars-link"
                            >
                              <span>{optionallyRename(d)}</span>
                              <ArrowRight className="theme-info__dwars-icon"/>
                            </Link>
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-1 col-l-1o2">
                  <div
                      className="theme-info__detail theme-info__detail--top-right"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_HOST}${afb3})`,
                      }}
                  ></div>
                </div>
              </div>
              <div className="row">
                <div className="col-1 col-l-1o2">
                  <div className="theme-info__detail theme-info__detail--bottom-left">
                    <h2 className="theme-info__title theme-info__cluster-title">
                      Cluster
                      <span className="theme-info__cluster-label">
                      {!!theme?.inhoud?.cluster &&
                          theme?.inhoud?.cluster}
                    </span>
                    </h2>
                    <div className="theme-info__cluster-content">
                      {!!theme?.inhoud?.clustertekst
                          ? clustertekst
                          : ""}
                    </div>
                  </div>
                </div>
                <div className="col-1 col-l-1o2">
                  <div className="theme-info__detail theme-info__detail--bottom-right">
                    <h2 className="theme-info__title">Clusters</h2>
                    <ul className="theme-info__clusters-list">
                      {clusters.map((c, i) => (
                          <li className="theme-info__clusters-list-item" key={i}>
                            <button
                                className={`theme-info__clusters-label theme-info__clusters-label--${colorByCluster(
                                    c.value
                                )}`}
                                onClick={() => handleClusterClick(c)}
                            >
                              {c.label}
                              <ArrowRight/>
                            </button>
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
      )}
      {videoId && <VideoModal youtubeId={videoId}/>}
    </div>
);
};

export default ThemaDetail;
