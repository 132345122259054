import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import ClusterIndex from "./views/ClusterIndex";
import DispositionIndex from "./views/DispositionIndex";
import GlobalReducer from "./hooks/useReducer";
import Viewport from "./hooks/useViewport";
import ThemeDetail from "./views/ThemeDetail";

function App() {
  return (
    <div className="App">
      <GlobalReducer>
        <Viewport>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/themas" element={<ClusterIndex />} />
            <Route path="/themas/:id" element={<ThemeDetail />} />
            <Route path="/maatregelen" element={<DispositionIndex />} />
          </Routes>
        </Viewport>
      </GlobalReducer>
    </div>
  );
}

export default App;
