import {useEffect, useRef, useState} from "react";
import {  useRefProvider } from "../hooks/useReducer";
import YouTube from "react-youtube";
import CloseIcon from "../assets/close-icon.svg";
import VideoTranscript from "./VideoTranscript";
import {fetchYouTubeData} from "../api";
import {useLocation, useNavigate} from "react-router-dom";
import FocusLock from "react-focus-lock";

const VideoModal = ({youtubeId}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const videoId = searchParams.get("videoID");
  const videoIsOpen = videoId ? true : false;
  const [youTubeData, setYoutubeData] = useState(null);
  const video = useRef();
  const videoModalLastElRef = useRef();
  const videoModalRef = useRef();
  const {videoTriggerRef, videoModalFirstElRef} = useRefProvider();

  useEffect(() => {
    const fetchYoutubeData = async () => {
      try {
        const getYoutubeData = await fetchYouTubeData(youtubeId);
        setYoutubeData(getYoutubeData);
      } catch (err) {
        console.error("Failed to fetch YouTube data:", err);
      }
    };
    fetchYoutubeData();
  }, [youtubeId]);

  useEffect(() => {
    if (videoModalFirstElRef.current) {
      videoModalFirstElRef.current.focus();
    }
    const timer = setTimeout(() => {
      if (videoModalFirstElRef.current) {
        videoModalFirstElRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [videoId, videoModalFirstElRef]);

  const closeModal = () => {
    searchParams.delete("videoID");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    const player = video?.current?.getInternalPlayer();
    if (player) {
      player.pauseVideo();
    }

    if (videoTriggerRef?.current) {
      videoTriggerRef.current.focus();
    }
  };

  const onPlayerReady = (event) => {
    const player = event.target;
    const videoTitle = player?.getVideoData()?.title;
    const iframe = event?.target?.getIframe();
    if (!iframe) {
      return;
    }
    // Set iframe attributes
    iframe.setAttribute("title", `Video '${videoTitle}'`);
    iframe.setAttribute("tabIndex", "0");
    iframe.setAttribute("aria-describedby", "video-transcript");
  };

  const handleOnKeyDown = (event) => {
    const {key} = event;
    const player = video?.current.getInternalPlayer();
    player.isPlaying = player.isPlaying || false;
    if (key === "Escape") {
      closeModal();
    }
  }

  if (!youTubeData) {
    return null;
  }

  const opts = {
    playerVars: {
      autoplay: 0,
      disablekb: 1,
      playerVars: {
        cc_load_policy: 1, // This will force captions on
        hl: 'nl',          // Sets the language for the captions (change as needed)
      },
    },
  };

  return (
      <FocusLock
          returnFocus={true}
          disabled={!videoIsOpen}
          onFocusReturn={() => {
            if (videoTriggerRef?.current) {
              videoTriggerRef.current.focus();
            }
          }}
      >
        <div
            id="video-modal"
            className={`video-modal ${videoIsOpen ? "video-modal--open" : ""}`}
            role="dialog"
            aria-labelledby="video-title"
            ref={videoModalRef}
            aria-modal="true"
            onKeyDown={handleOnKeyDown}
            key={youTubeData.title}
        >
          <button
              aria-label={`Sluit de video "${youTubeData.title}"`}
              ref={videoModalFirstElRef}
              onClick={closeModal}
              className="video-modal__close-icon"
          >
            <img
                src={CloseIcon}
                alt={`Sluit de video "${youTubeData.title}"`}
            />
          </button>
          {youTubeData.title && <span id="video-title" className="visually-hidden">{youTubeData.title}</span>}
          <div className="video-modal__youtube">
            {videoId && (
                <YouTube
                    ref={video}
                    videoId={videoId}
                    opts={opts}
                    onReady={onPlayerReady}
                    aria-describedby="video-transcript"
                />
            )}
          </div>

          {/* Accessibility video transcript section */}
          {videoId && <VideoTranscript videoId={videoId}/>}

          {/* Hidden button for accessibility */}
          <button
              ref={videoModalLastElRef}
              aria-label={`Sluit de video "${youTubeData.title}"`}
              onClick={closeModal}
              style={{position: "absolute", left: "-9999px"}} // Visually hidden
          >
          </button>
        </div>
      </FocusLock>
  );
};

export default VideoModal;
