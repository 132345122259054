const CloseButton = ({ onClick, ariaLabel = "Close", className = "", closeButtonRef }) => (
    <button
        className={`mobile-filter__close-button ${className}`}
        aria-label={ariaLabel}
        onClick={onClick}
        ref={closeButtonRef}
    >
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden={true}
        >
            <title>Sluit filters</title>
            <circle
                cx="12"
                cy="12"
                r="11"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
            />
            <rect
                x="16"
                y="11"
                width="2"
                height="8"
                transform="rotate(90 16 11)"
                fill="currentColor"
            />
        </svg>
    </button>
);

export default CloseButton;
