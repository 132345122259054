import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { Link } from "react-router-dom";
import bgImage from "../assets/hh-bg.jpg";
import LogoDelfsland from "../assets/logo-delfsland.svg";
import HeadMeta from "../components/HeadMeta";
import { AccessibilityIcon, BookIcon} from "../components/Icons";

const animationData = require("../assets/logo-animation.json");

function Home() {
  return (
    <div className="Splash" style={{backgroundImage: `url(${bgImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
      <HeadMeta pageTitle="Homepagina | Waterwerk WBP6 Hoogheemraadschap van Delfland" />
      <header className="Splash-header">
        <div className="container">
          <div className="row">
            <div className="col-l-1o2 text-align-center text-align-lg-left">
              <img src={LogoDelfsland} alt="Logo Hoogheemraadschap van Delfland" />
            </div>
            <div className="col-l-1o2 text-align-right" style={{display: "flex", alignItems: "center"}}>
              <a
                className="button button--features"
                href="/files/hhd-2223-wbp6-hoofdlijnen-toegankelijk-wcag.pdf"
                target="_blank"
                aria-label="Open PDF bestand: hhd-2223-wbp6-hoofdlijnen-toegankelijk-wcag.pdf"
              >
                <BookIcon />
                <span className="button__label">WBP6 op hoofdlijnen</span>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="Splash-body">
        <div className="container">
          <div className="row">
            <div className="col-l-2o3 offset-l-1o6">
              <figure style={{ textAlign: 'center' }}>
                <Lottie
                    loop={false}
                    animationData={animationData}
                    play
                    rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                    role="img"
                    aria-labelledby="waterwerk-logo"
                />
                <figcaption className="visually-hidden"  id="waterwerk-logo" >
                  Waterwerk logo
                </figcaption>
              </figure>
              <h1 className="Splash-subtitle">
                het 6de waterbeheer&shy;programma
                <br />
                2022 - 2027
              </h1>
              <div className="Splash-actions">
                <div className="row">
                  <div className="col-l-1o2 text-align-center text-align-lg-right">
                    <Link className="button button--alt" to="/themas">
                      thema’s
                    </Link>
                  </div>
                  <div className="col-l-1o2 text-align-center text-align-lg-left">
                    <Link className="button" to="/maatregelen">
                      maatregelen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row"  style={{display: "flex", justifyContent: "end", marginBottom: "32px"}}>
          <div className="col-l-1o2 text-align-right">
            <a
                className="button button--features"
                href="/files/inspectie-wbp6hhdelflandnl-11-2024.pdf"
                target="_blank"
                aria-label="Open PDF bestand: inspectie-wbp6hhdelflandnl-11-2024.pdf"
            >
              <AccessibilityIcon />
              <span className="button__label">Toegankelijkheid</span>
            </a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
