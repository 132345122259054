import ReactHtmlParser from "react-html-parser";
import ContextPopper from "../components/ContextPopper";

const replaceTerm = (content, { name }) => {
  const pattern = new RegExp(`\\b${name}\\b`, 'ig');
  const replace = content.replace(pattern, (match) => {
    const replace = `<Contextpopper data-matched-text-word="${match}">${name}</Contextpopper>`;
    return replace;
  });
  return replace;
};

const parseTerm = (content, thesaurusData) => {
  // Split the content by <a> tags to avoid replacing inside them
  const regex = /(<a\b[^>]*>.*?<\/a>)/gi;
  const parts = content.split(regex);

  // Iterate through parts and replace terms outside <a> tags
  const updatedContent = parts.map((part) => {
    if (part.startsWith('<a')) {
      return part; // Skip replacements inside <a> tags
    } else {
      thesaurusData.forEach((term) => {
        part = replaceTerm(part, term);
      });
      return part;
    }
  });

  return updatedContent.join('');
};

const transform = (node, index) => {
  if (node.type === "tag" && node.name === "contextpopper") {
    const { 'data-matched-text-word': matchedTextWord } = node.children[0].parent.attribs;
    return <ContextPopper key={index} title={node.children[0].data} matchedTextWord={matchedTextWord} />;
  }
};

const wordListParser = (content, thesaurusData) => {
  if (content === undefined || thesaurusData === undefined) {
    return ReactHtmlParser("");
  }

  return ReactHtmlParser(parseTerm(content, thesaurusData), {
    transform: transform,
  });
};

export default wordListParser;