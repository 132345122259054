import { useEffect, useState } from "react";
import { getDisposition } from "../api";
import { useSearchParams } from "react-router-dom";
import DispositionCardModal from "./DispositionCardModal";
import { colorByCluster } from "../helpers/colorCluster";

const DispositionModal = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('dispositionId') || "";
  const [loaded, setLoaded] = useState(false);
  const [disposition, setDisposition] = useState(null);
  const dispositionModalOpen = id ? true : false;

  useEffect(() => {
    const fetchDisposition = async () => {
      if (!id) {
        setDisposition(null);
        return;
      }

      const dispositionData = await getDisposition(id);
      if (dispositionData) {
        setDisposition({ ...dispositionData });
      }
    };

    fetchDisposition();
  }, [id]);
  const color = disposition && disposition.color ? colorByCluster(disposition.color) : "";


  useEffect(() => {
    document.body.classList.toggle("modal-open", dispositionModalOpen);
    setLoaded(dispositionModalOpen);
    if (!dispositionModalOpen) {
      const modalEl = document.getElementById("modal-disposition");
      if (modalEl) {
        modalEl.scrollTop = 0;
      }
    }
  }, [dispositionModalOpen]);

  if (!id) {
    return null;
  }

  return (
      <div
          id="modal-disposition"
          className={`disposition-modal ${
              dispositionModalOpen
                  ? `disposition-modal--open disposition-modal--open--${color}`
                  : ""
          } ${
              color
                  ? `disposition-modal--${color}`
                  : ""
          } ${loaded ? "disposition-modal--animate" : ""}`}
          aria-hidden={dispositionModalOpen ? false : true}
          aria-disabled={dispositionModalOpen ? false : true}
          tabIndex={dispositionModalOpen ? 0 : -1}
      >
        <div className="container">
          <div className="row">
            <div className="col-1o1">
              <DispositionCardModal {...disposition} isModal={true} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default DispositionModal;
